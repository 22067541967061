<template>
  <div>
    <Header :title="'充值'" :back="true"/>
      <div class="three-box">
        <div class="box-items flex-box bank" v-for="item in banksList" :key="item.id" @click="changeBank(item.type)">
          <div class="bank-icon"><img :src="require('@/assets/img/1.png')"/></div>
          <div>
            <div>{{item.enbank}}
              <span class="tj" v-if="item.tj">推荐</span>
            </div>
            <div class="sub">{{item.xe}}：{{item.xemoney}}</div>
            <div class="sub">{{item.sxf}}：{{item.sxfmoney}}</div>
          </div>
          <div style="position:absolute;top:44px;right:16px"><i class="el-icon-arrow-right"></i></div>
        </div>
      </div> 
    <div class="tips"><i class="el-icon-info" style="color:#999"></i> 仅支持资金充值到本人同名的木星国际账户中。</div>
    <div class="tips"><i class="el-icon-info" style="color:#999"></i> 如想汇款到他人账户，可使用木星国际内部站长功能进行资金流转。</div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      banksList:[{
        type:"hongkong",
        enbank:"香港账户汇款",
        xe:"限额",
        xemoney:"无",
        sxf:"手续费",
        tj:"推荐",
        sxfmoney:"约7美元(由银行收取)"
      },{
        type:"other",
        enbank:"内地/其他地区账户汇款",
        xe:"限额",
        xemoney:"5万美元/笔(取决于银行)",
        sxf:"手续费",
        sxfmoney:"约20-50美元(由银行收取)"
      }],
    }
  },
  methods:{
    changeBank(type){
      if(type=='hongkong'){
        this.$router.push({path:'transferhk'})
      }else{
        this.$router.push({path:'transfero'})
      }
      
    }
  }
}
</script>
<style lang="scss" scoped>

.three-box .bank{
  margin-top:2.5%;
  align-items: center;
  flex-shrink: 0;
  margin-bottom:2.5%;
  position: relative;
  .bank-icon{
    width: 50px;
    text-align: center;
  }
  .sub{
    color: #464646;
    font-size: .9rem;
    margin-top:.5rem
  }
}

.tj{
  font-size:.6rem;
  border:1px solid #d10921;
  border-radius:12px;
  padding:2px 6px;
  color: #d10921;
  margin-left:10px
}
.tips{
  margin: 1% 3%;
  color: #888;
  font-size: .8rem;

}
</style>